import { Empty, List, Select, Typography } from 'antd';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SkeletonProjectCard from '../../../component/Skeleton/ProjectCard';
import { JobHistory } from '../../../helper/type';
import { useJobService } from '../../../service/job.service';

import { Div } from '../../../framework';
import ApplicationCard from './ApplicationCard';
import { RootState } from '../../../store/store';
import { useAccountingService } from '../../../service/accounting.service';
import {
  setActivities,
  setSelfBilledInvoices,
} from '../../../store/talent.slice';
import { featureToggle } from '../../../../config/app.config';
import { useActivityService } from '../../../service/activity.service';
import { useApplicantStatus } from '../../../hook/useApplicantStatus';
import { statusText } from '../../../helper/constants';
import getApplicationViewCount from '../../../helper/activity/getApplicationViewCount';

const { Text } = Typography;
const { Option } = Select;

type HistoryData = JobHistory & { loading: boolean };

type Props = {
  type: string;
};

const HistoryTabContent: FunctionComponent<Props> = ({ type }) => {
  const dispatch = useDispatch();
  const { talent } = useSelector((state: RootState) => state.user);
  const { modal, loadStack } = useSelector((state: RootState) => state.app);
  const { mappedActivities } = useSelector((state: RootState) => state.talent);
  const [data, setData] = useState<HistoryData[]>([]);
  const [list, setList] = useState<HistoryData[]>([]);
  const [filter, setFilter] = useState<string>('all');
  const [key, setKey] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [observer, setObserver] = useState<IntersectionObserver>();
  const [currentLastItem, setCurretLastItem] = useState<HTMLElement>();

  const { getLatestActivity, getApplicantStatus } =
    useApplicantStatus('talent');

  const activityService = useActivityService();
  const jobsService = useJobService();
  const accountingService = useAccountingService();

  const isLoadingApplication =
    loadStack.filter((stack) => stack.startsWith('job/application/list'))
      .length > 0;

  const filteredList = useMemo(() => {
    return list
      .map((d) => {
        const latestActivity = getLatestActivity(d.roleId, d.talentId);
        return {
          ...d,
          latestActivity,
          applicantStatusKey:
            getApplicantStatus({ activity: latestActivity }) ?? 'none',
          viewCount: getApplicationViewCount(mappedActivities[d.roleId] ?? []),
        };
      })
      .filter((d) =>
        filter === 'all' ? true : d.applicantStatusKey === filter
      );
  }, [list, mappedActivities, filter]);

  const loadData = async (fetchKey?: string) => {
    const res = await jobsService.getApplicationsByTalent(
      { type, withdraw: type === 'past' },
      fetchKey
    );
    res.data.map(({ roleId, talentId }) =>
      (async () => {
        const { data: activities } =
          await activityService.listRoleTalentActivity({ roleId, talentId });
        dispatch(setActivities({ roleId, activities }));
      })()
    );

    const applications = res.data.sort((a, b) => {
      if (a.role.shootDates && b.role.shootDates) {
        const shootDateA = a.role.shootDates[0];
        const shootDateB = b.role.shootDates[0];
        return shootDateB.localeCompare(shootDateA);
      }

      if (a.role.shootDates) return -1;
      if (b.role.shootDates) return 1;

      return b.appliedAt.localeCompare(a.appliedAt);
    });
    setData((prevData) => {
      const newData = prevData.concat(
        applications.map((d) => ({ ...d, loading: false }))
      );

      setList(newData);

      return newData;
    });
    setKey(res.key);
  };

  useEffect(() => {
    if (!featureToggle.invoice) return;

    (async () => {
      if (!talent.id) return;

      const { data } = await accountingService.listSelfBilledInvoicesByPayeeId(
        talent.id
      );
      dispatch(setSelfBilledInvoices(data));
    })();
  }, []);

  useEffect(() => {
    if (!modal.name) {
      setData([]);
      loadData();
    }
  }, [modal.name]);

  const onLoadMore = useCallback(async () => {
    // still loading previous fetch
    if (loading) {
      return;
    }

    // no more key to paginate
    if (key == '') {
      return;
    }

    setLoading(true);
    const skeletonData = new Array(1).fill({ loading: true });
    setList((prevData) => prevData.concat(skeletonData));
    await loadData(key);
    setLoading(false);
  }, [key, loading]);

  useEffect(() => {
    const opts = {
      root: null,
      rootMargin: '0px',
      threshold: 0.7,
    };

    const observer = new IntersectionObserver((entry) => {
      const currentLastItem = entry[0];
      if (currentLastItem.isIntersecting) {
        onLoadMore();
      }
    }, opts);

    setObserver(observer);

    return () => observer.disconnect();
  }, [onLoadMore]);

  useEffect(() => {
    if (observer && currentLastItem) {
      observer.disconnect();
      observer.observe(currentLastItem);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [observer, currentLastItem]);

  return !isLoadingApplication && data.length === 0 ? (
    <Div className='zero-state'>
      <Empty
        description={<>No {type === 'past' ? 'past' : 'active'} applications</>}
      />
    </Div>
  ) : (
    <>
      <Div className='select-filter'>
        <Text>Filter status by</Text>
        <Select
          className='selector'
          defaultValue='all'
          value={filter}
          onChange={setFilter}
        >
          <Option value='all'>All</Option>
          {Object.keys(statusText).map((status) => (
            <Option key={status} value={status}>
              {statusText[status]}
            </Option>
          ))}
          <Option value='notSelected'>Not Selected</Option>
        </Select>
      </Div>
      <List
        className='project-list'
        loading={isLoadingApplication}
        dataSource={filteredList}
        itemLayout='vertical'
        footer={
          !isLoadingApplication &&
          key == '' &&
          data.length > 0 && (
            <div className='end-of-list'>
              <span>That&apos;s all for {type} application!</span>
            </div>
          )
        }
        renderItem={(item, index) => {
          const isLastItem = index === data.length - 1;

          return (
            <SkeletonProjectCard key={item.roleId} loading={item.loading}>
              <List.Item
                ref={(el) => {
                  if (isLastItem && el) {
                    setCurretLastItem(el);
                  }
                }}
                key={item.roleId}
              >
                <ApplicationCard
                  key={item.roleId}
                  job={item}
                  allowWithdraw={type === 'current'}
                  pastApplication={type === 'past'}
                />
              </List.Item>
            </SkeletonProjectCard>
          );
        }}
      ></List>
    </>
  );
};

export default HistoryTabContent;
